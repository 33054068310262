import '@assets/main.css'
import { ManagedUIContext } from '@components/context'
import Head from '@components/Head'
import { GTM_ID, pageView } from '@framework/lib/gtm'
import { CustomWindow } from '@framework/types/site'
import type { AppProps } from 'next/app'
import Script from 'next/dist/client/script'
import { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

const Noop: FC = ({ children }) => <>{children}</>
declare let window: CustomWindow

function MyApp({ Component, pageProps }: AppProps) {
  const Layout =  (Component as any).Layout || Noop
  const router = useRouter()


  useEffect(() => {
    router.events.on('routeChangeComplete', pageView)
    return () => {
      router.events.off('routeChangeComplete', pageView)
    }
  }, [router.events])

  return (
    <>
      <Head/>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}&l=dataGtm`} strategy="afterInteractive"  />
      <Script id="google-analytics" strategy="afterInteractive" >
        {`
          window.gptadslots = []
          window.dataGtm = window.dataGtm || [];
          function gtag(){window.dataGtm.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTM_ID}');
          gtag('config', 'AW-694934550');
        `}
      </Script>
      <Script id="google-ads" strategy="afterInteractive" >
        {`
          gtag('event', 'conversion', {
            'send_to': 'AW-694934550/-jQWCMqY1KQZEJa4r8sC'
          });
        `}
      </Script>
      <ManagedUIContext>
        <Layout pageProps={pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ManagedUIContext>
    </>
  )
}

export default MyApp
