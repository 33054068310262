import { CustomWindow } from "@framework/types/site"

export const GTM_ID = 'GTM-52TQ57'
declare let window: CustomWindow
export const pageView = (url:string) => {
  window.dataGtm.push({
    event:'pageview',
    page:url
  })
}

export const screenHome = (action:string) => {
  window.dataGtm.push({
    event:'screenHome',
    acciones: action
  })
}

export const buttonPage = (action:string) => {
  window.dataGtm.push({
    event:'buttonPage',
    acciones: action
  })
}