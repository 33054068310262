import { FC } from 'react'
import NextHead from 'next/head'
import organization from '@framework/seo/organization.json'
import website from '@framework/seo/website.json'
import { useRouter } from 'next/router'
const Head: FC = () => {
  const router = useRouter()
  return(
    <NextHead>
      <link rel="apple-touch-icon" sizes="57x57" href="/img/apple-touch-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/img/apple-touch-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/img/apple-touch-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/img/apple-touch-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/img/apple-touch-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon-180x180.png" />
      <link rel="manifest" href="/manifest.json" />
      <link rel="canonical" href={`https://cuantoestaeldolar.pe${router.asPath}`}/>
      <link rel="amphtml" href="https://amp.cuantoestaeldolar.pe/" />
      <script id='organization' type='application/ld+json' dangerouslySetInnerHTML={{ __html: `${JSON.stringify( organization )}` }} />
      <script id='website' type='application/ld+json' dangerouslySetInnerHTML={{ __html: `${JSON.stringify( website )}` }} />
    </NextHead>
  )
}

export default Head