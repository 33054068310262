import { createContext, FC } from 'react';

const initialState = {
  hola: "mundo",
  map: false
}

export const UIContext = createContext<any>(initialState);

export const UIProvider: FC = (props)=>{

  return <UIContext.Provider value={ initialState } {...props} />
}

export const ManagedUIContext: FC = ({ children }) => (
  <UIProvider>
    {children}
  </UIProvider>
)